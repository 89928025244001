import React, { useEffect } from 'react';
import Loading from './loading';
import Auth from './Auth';

// Create a context for user data
export const UserContext = React.createContext({});
const BaseUrl = 'https://api.tutorscom.com/api';
const AppUrl = 'https://api.tutorscom.com';

// const BaseUrl = 'http://127.0.0.1:8000/api';
// const AppUrl = 'http://127.0.0.1:8000';

export default function UserProvider({ children }) {
    const hasToken = localStorage.hasOwnProperty('user') ? true : false;

    const [user, setUser] = React.useState(
        hasToken ? JSON.parse(localStorage.user) : {}
    );

    const Authorizing = async () => {
        if (!(await Auth.session({ token: user.token, 'BaseUrl': BaseUrl }))) {
            localStorage.removeItem('user');
            setUser({});
            return false;
        }
        setUser({
            token: user.token,
            email: Auth.user.email,
            first_name: Auth.user.first_name,
            last_name: Auth.user.last_name,
            profile: Auth.user.profile,
            about: Auth.user.about,
            phone: Auth.user.phone,
            verify: Auth.user.verify
        });
        return true;
    }

    useEffect(() => {
        if ('token' in user && !('email' in user)) {
            Authorizing();
        };
        window.addEventListener('beforeunload', () => {
            var user = JSON.parse(localStorage.getItem('user'));
            if(user.remember == false){
                setUser({});
                localStorage.removeItem('user');
            }

        });

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('beforeunload', () => {
                var user = JSON.parse(localStorage.getItem('user'));
                if(user.remember == false){
                    setUser({});
                    localStorage.removeItem('user');
                }
            });
        }
    }, [user]);

    if ('token' in user && !('email' in user)) {
        return <Loading display="Authorizing" />
    }

    function logout() {
        setUser({});
        localStorage.removeItem('user');
        window.location.href = '/';
    }

    return (
        <UserContext.Provider value={{ user, setUser, logout, BaseUrl, AppUrl }}>
            {children}
        </UserContext.Provider>
    );
}
